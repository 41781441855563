import { parse, extract } from "query-string"
import urlParse from "url-parse"

import { AppLink, AppLinkPath, AppLinkUrl } from "@treefort/api-spec"

import config from "../config"
import { Params } from "../navigation/routes"

type AppLinkResult<T extends string | null> = T extends string
  ? AppLinkPath | AppLinkUrl
  : null

/**
 * Parse an absolute link to determine whether it's pointing to a path within
 * the app or a URL on another domain.
 */
export function getAppLinkFromUrl<T extends string | null>(
  urlString?: T,
): AppLinkResult<T> {
  // Garbage in garbage out
  if (!urlString) {
    return null as AppLinkResult<T>
  }

  // Parse the url for inspection/modification. If the first part of the URL
  // looks like a domain name (e.g. "www.google.com") then manually add a
  // protocol so that it parses correctly. This extends a bit of grace towards
  // administrators who enter URLs like "myapp.com/path" into services outside
  // of our control where we can't validate the input.
  const url = urlParse(
    isProbablyDomainName(urlString.split("/")[0])
      ? `https://${urlString}`
      : urlString,
    {},
  )

  // If we received a relative path or an absolute link on the same domain then
  // return a "path" object containing just the link's path.
  if (!url.hostname || url.hostname === config.DOMAIN_NAME) {
    return {
      type: "path",
      path: "/" + url.pathname.replace(/^\/*/, ""),
      params: getStringParamsFromUrl(urlString),
    } as AppLinkResult<T>
  }

  // If we received an absolute link on a different domain then upgrade http to
  // https and return a "url" object containg the full link.
  if (url.protocol === "http") {
    url.set("protocol", "https")
  }
  return { type: "url", url: url.toString() } as AppLinkResult<T>
}

/**
 * Convert external "url" links that point back to the app to "path" links so
 * that they are handled like internal navigation instead of opening a second
 * instance of the app
 */
export function normalizeAppLink(link: AppLink) {
  return link.type === "url" ? getAppLinkFromUrl(link.url) : link
}

/**
 * Returns params contained in an app link (if any) mixed with override params
 * (if provided). Returns undefined if no params are found.
 */
export function getParamsFromAppLink(link: AppLink, overrideParams?: Params) {
  switch (link.type) {
    case "url": {
      const mergedParams = {
        ...getStringParamsFromUrl(link.url),
        ...overrideParams,
      }
      return Object.keys(mergedParams).length ? mergedParams : undefined
    }
    case "path": {
      const mergedParams = { ...link.params, ...overrideParams }
      return Object.keys(mergedParams).length ? mergedParams : undefined
    }
    default:
      return undefined
  }
}

export function getAppLinkFromNotificationData(data?: Record<string, unknown>) {
  // Garbage in garbage out
  if (!data) {
    return null
  }

  // Extract the link property from the notification data. HACK: Ignore the
  // key's case and any leading/trailing whitespace. At the time of this writing
  // it is necessary to use the Firebase UI to send push notifications and it is
  // very hard to get admin users to remember to lowercase and trim their own
  // strings.
  const url = Object.entries(data).find(([key]) =>
    /^\s*link\s*$/i.test(key),
  )?.[1]

  // Garbage in garbage out
  if (typeof url !== "string") {
    return null
  }

  return getAppLinkFromUrl(url)
}

/**
 * Returns true if the provided value looks like a domain name (e.g.
 * "something.com" "app.something.io").
 */
function isProbablyDomainName(value: unknown) {
  return (
    typeof value === "string" &&
    /^[a-z\d]([a-z\d-]{0,61}[a-z\d])?(\.[a-z\d]([a-z\d-]{0,61}[a-z\d])?)+$/i.test(
      value,
    )
  )
}

/**
 * Returns an object containing all string query parameters from an URL or
 * undefined if the URL does not contain string parameters.
 */
function getStringParamsFromUrl(url: string) {
  const allParams = parse(extract(url))
  const stringParams = Object.fromEntries(
    Object.entries(allParams).flatMap(([key, value]) =>
      typeof value === "string" ? [[key, value]] : [],
    ),
  )
  return Object.keys(stringParams).length ? stringParams : undefined
}
